import * as A11yDialog from 'a11y-dialog';
import { lockBody, unlockBody } from '../helpers/lockBody';
import * as YouTubeIframeLoader from 'youtube-iframe';
import Player from '@vimeo/player';
declare global {
    interface Window {
        YT: any;
    }
}


export class Modal {
    modal: any;
    modalElement: HTMLElement;
    bodyStyles: string;
    video?: any;

    constructor(modalElement: HTMLElement, container?: HTMLElement) {
        if (container != null) {
            this.modal = new A11yDialog(modalElement as Element, container as Element);
        }
        else {
            this.modal = new A11yDialog(modalElement as Element);
        }
        this.modalElement = modalElement;
        this.modal.on('show', () => {
            this.bodyStyles = lockBody();
            document.body.style.overflow = 'hidden';
            this.initVideo();
        });
        this.modal.on('hide', () => {
            unlockBody(this.bodyStyles);
            document.body.style.overflow = '';
            if (this.video) {
                this.pauseVideo();
            }
        });
    }

    initVideo() {
        let video = this.modalElement.querySelector('[data-video-type]');
        if (video) {

            let videoType = video.getAttribute('data-video-type');
            if (videoType == 'vimeo') {
                this.vimeoInit();
            }
            else if (videoType == 'youtube') {
                this.youtubeInit();
            }
            else {
                this.nativeVideoInit();
            }
        }
    }

    vimeoInit() {
        let vimeoIframe = this.modalElement.querySelector('iframe');
        if (!vimeoIframe.src) {
            vimeoIframe.src = vimeoIframe.getAttribute('data-src');
            let vimeoPlayer = new Player(vimeoIframe, {});
            this.video = vimeoPlayer;
        }
    }

    youtubeInit() {
        let youtubeIframe = this.modalElement.querySelector('iframe');
        if (!youtubeIframe.src) {
            let uniqueID = this.modalElement.id;
            
            if (YouTubeIframeLoader && window.YT) {
                let playerID = `youtube-player-${uniqueID}`;
                youtubeIframe.id = playerID;
                youtubeIframe.src = youtubeIframe.getAttribute('data-src');
                let youtubePlayer = new window.YT.Player(playerID, {});
                this.video = youtubePlayer;
            }
            else {
                YouTubeIframeLoader.load(YT => {
                    let playerID = `youtube-player-${uniqueID}`;
                    youtubeIframe.id = playerID;
                    youtubeIframe.src = youtubeIframe.getAttribute('data-src');
                    let youtubePlayer = new YT.Player(playerID, {});
                    this.video = youtubePlayer;
                });
            }
        }
    }

    nativeVideoInit() {
        let videoElement = this.modalElement.querySelector('video');
        if (videoElement) {
            let videoSource = videoElement.querySelector('source');
            if (!videoSource.src) {
                videoSource.src = videoSource.getAttribute('data-src');
                videoElement.load();
            }
            this.video = videoElement;
        }
    }

    pauseVideo() {
        let videoContainer = this.modalElement.querySelector('[data-video-type]');

        if (videoContainer.getAttribute('data-video-type') == 'vimeo') {
            this.video.pause();

        }
        else if (videoContainer.getAttribute('data-video-type') == 'youtube') {
            this.video.pauseVideo();
        }
        else {
            if (!this.video.paused) {

                this.video.pause();
            }
        }
    }
}