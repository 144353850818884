import { lockBody, unlockBody } from '../helpers/lockBody';

// Static strings
const openMenuClasses = ['mnav-menu-is-open'];
const darkThemeClass = "theme-dark";
const lightThemeClass = "theme-light";
const navLoadedClass = 'mheader-has-loaded';
const menuSlideStateAttribute = 'data-nav-is-showing';
const navId = "mheader";
const menuId = "nav-menu";

class MainNavigation {
    container: HTMLElement;
    menuTrigger: HTMLButtonElement;
    menuPanel: HTMLElement;
    menuFocusableChildren: Array<HTMLButtonElement | HTMLAnchorElement | HTMLInputElement>;
    menuIsOpen: boolean;
    navIsShowing: boolean;
    existingBodyStyles: string;
    lastScrollPosition: number;
    hasLoaded: boolean;
    currentNavHeight: number;
    bodyEl: HTMLBodyElement;
    onPageAnchorLinks: HTMLAnchorElement[];
    navSearchCloseBtn: null | HTMLElement;
    constructor(navigation: HTMLElement) {
        this.container = navigation;
        this.menuTrigger = this.container.querySelector(`[aria-controls="${menuId}"]`) as HTMLButtonElement;
        this.menuPanel = document.querySelector(`#${menuId}`) as HTMLElement;
        this.menuFocusableChildren = [].slice.call(this.menuPanel.querySelectorAll('button, a, input'));
        this.bodyEl = document.body as HTMLBodyElement;
        this.menuIsOpen = false;
        this.navIsShowing = true;
        this.hasLoaded = false;
        this.existingBodyStyles = '';
        this.navSearchCloseBtn = null;
        // Intercept on-page links so that the offset recalculates before the browser reaches it
        // This makes sure that the target is always comfortably visible below the nav
        this.onPageAnchorLinks = [].slice.call(document.querySelectorAll('a[href^="#"]'));
        this.onPageAnchorLinks.forEach(link => {
            link.addEventListener('click', (e: Event) => {
                e.preventDefault();
                const hash = link.getAttribute('href');
                const target = document.querySelector(hash) as HTMLElement;
                if (target) {
                    if (this.navIsShowing && target.offsetTop > window.pageYOffset) {
                        this.slideUp();
                    }
                    else if (!this.navIsShowing && target.offsetTop < window.pageYOffset) {
                        this.slideDown();
                    }
                    setTimeout(() => {
                        target.scrollIntoView(true);
                        target.focus();
                        window.location.hash = hash;
                    }, 0);
                }
            })
        })
    }

    init = () => {
        this.menuTrigger.addEventListener('click', () => {
            if (this.menuIsOpen) {
                this.closeMenu();
            }
            else {
                this.openMenu();
            }
        });

        document.body.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.which == 27 && this.menuIsOpen) { // Escape key
                this.closeMenu();
                this.menuTrigger.focus();
            }
            if (e.which == 9) { // Tab key. Show nav if tabbed into, or close menu if tabbed out of.
                const focusInNav = this.menuPanel.contains(document.activeElement) || this.container.contains(document.activeElement);
                if (focusInNav && !this.navIsShowing) {
                    this.slideDown();
                }
                if (!focusInNav && this.menuIsOpen) {
                    this.closeMenu();
                }
            }
        });

        // Setup on page load
        this.closeMenu();
        this.setPageOffset();
        this.slideDown();
    }

    slideDown = () => {
        this.bodyEl.setAttribute(menuSlideStateAttribute, 'true');
        this.container.setAttribute(menuSlideStateAttribute, 'true');
        this.navIsShowing = true;
        this.setCssVariables();
    }

    slideUp = () => {
        this.bodyEl.setAttribute(menuSlideStateAttribute, 'false');
        this.container.setAttribute(menuSlideStateAttribute, 'false');
        this.navIsShowing = false;
        this.setCssVariables();
    }

    openMenu = () => {
        this.hideSearchForm();
        this.menuPanel.setAttribute('aria-hidden', 'false');
        this.menuTrigger.setAttribute('aria-expanded', 'true');
        this.enableTabbableChildren();
        this.menuIsOpen = true;
        this.existingBodyStyles = lockBody();
        openMenuClasses.forEach(cssClass => {
            this.container.classList.add(cssClass);
            this.menuPanel.classList.add(cssClass);
        });
    }

    hideSearchForm() {
        this.navSearchCloseBtn = document.querySelector('[data-nav-search-close]') as HTMLElement; 
        if (this.navSearchCloseBtn)
            this.navSearchCloseBtn.click();
    }

    closeMenu = () => {
        this.menuPanel.setAttribute('aria-hidden', 'true');
        this.menuTrigger.setAttribute('aria-expanded', 'false');
        this.disableTabbableChildren();
        this.menuIsOpen = false;
        unlockBody(this.existingBodyStyles);
        openMenuClasses.forEach(cssClass => { 
            this.container.classList.remove(cssClass);
            this.menuPanel.classList.remove(cssClass);
        });
        this.setPageOffset();
    }

    enableTabbableChildren = () => {
        this.menuFocusableChildren.forEach(child => {
            child.tabIndex = 0;
        })
    }

    disableTabbableChildren = () => {
        this.menuFocusableChildren.forEach(child => {
            child.tabIndex = -1;
        })
    }

    setPageOffset = () => {
        if (!this.hasLoaded) {
            this.container.classList.add(navLoadedClass);
            this.hasLoaded = true;
        }
        this.currentNavHeight = this.container.offsetHeight;
        this.setCssVariables();
    }

    updateNavBg = (bgShouldBeOpaque: boolean) => {
        const themeData = this.container.dataset.theme.split(' ');
        if (bgShouldBeOpaque) {
            themeData.forEach(string => this.container.classList.remove(string));
            this.container.classList.add(lightThemeClass);
        }
        else {
            if (lightThemeClass !== this.container.dataset.theme) {
                //Remove the class that we added that might not be the one that was there when the page loaded
                this.container.classList.remove(lightThemeClass);
            }
            //Reapply the theme that was there on page load.
            themeData.forEach(string => this.container.classList.add(string));
        }
    } 

    scrollHandler = () => {
        if (this.hasLoaded) {
            const currentPosition = window.pageYOffset;
            const delta = currentPosition - this.lastScrollPosition;
            this.lastScrollPosition = currentPosition;
            
            if (this.navIsShowing) {
                if (delta > 0 && currentPosition > this.currentNavHeight && !this.menuIsOpen)
                    this.slideUp();
                if (delta < 0 && currentPosition < this.currentNavHeight && !this.menuIsOpen)
                    this.updateNavBg(false);
            }
            else if (delta < 0 || currentPosition < this.currentNavHeight) {
                this.slideDown();
                this.updateNavBg(true);
            }
        }
    }

    resizeHandler = () => {
        this.setPageOffset();
    }

    setCssVariables = () => {
        document.documentElement.style.setProperty('--nav-clearance', `${this.currentNavHeight}px`);
        document.documentElement.style.setProperty('--nav-margin-offset', `${this.navIsShowing ? this.currentNavHeight : 0}px`);
    }

    get isTheMenuOpen() {
        return this.menuIsOpen;
    }
}

let mainNavigation: MainNavigation = null;
const navContainer = document.querySelector(`#${navId}`) as HTMLElement;
if (navContainer) {
    mainNavigation = new MainNavigation(navContainer);
    (window as any).mainNavigation = mainNavigation;
}
export { mainNavigation };