import Splide from '@splidejs/splide';
import Video from '@splidejs/splide-extension-video';
import debounce = require('lodash.debounce');

// Ignore the typescript issues on the pagination property and slideFocus, it's just incorrect typings from the library
export class Playlist {
    gallery: Splide;
    constructor(element: HTMLElement) {
        this.gallery = new Splide(element, {
            accessibility: true,
            drag: true,
            perPage: 4,
            perMove: 4,
            // @ts-ignore
            pagination: false,
            // @ts-ignore
            slideFocus: false,
            speed: 500,
            focus: 0,
            //autoWidth: true,
            arrows: true,
            breakpoints: {
                1024: {
                    perPage: 3,
                    perMove: 3,
                },
                767: {
                    perPage: 1,
                    perMove: 1,
                },
            },
            keyboard: 'focused',
            padding: {
                left: '0rem',
                right: '0rem'
            },
            gap: '.5rem'
        });
        this.gallery.mount({ Video });
    }
}