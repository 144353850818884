import { nodeListToArray } from "../../Content/helpers/nodeListToArray";
import { ShowMore } from "./showMore";

export class GridBlock extends ShowMore {
    public toggleButtons: HTMLButtonElement[];
    public itemToToggleClassOn: HTMLElement;
    public showAllClass: string = "show-all";
    public showMoreText: string;
    public gridItems: HTMLElement[];
    constructor(toggleButtons: HTMLButtonElement[]) {
        super(toggleButtons);
        this.gridItems = this.toggleButtons != null && this.toggleButtons.length > 0 ? nodeListToArray(document.querySelectorAll(`[data-grid-item="${this.toggleButtons[0].dataset.showMore}"]`)) : []
    }

    classLogic(button: HTMLButtonElement) {
        if (this.itemToToggleClassOn) {
            if (!this.itemToToggleClassOn.classList.contains(this.showAllClass)) {
                this.addShowMoreClass();
                this.itemToToggleClassOn.focus();
                this.showMoreText = button.textContent;
                button.textContent = button.dataset.showLessText;
                this.showAllGridItems();
            }
            else {
                this.removeShowMoreClass();
                button.textContent = this.showMoreText;
                this.hideExtraGridItems();
            }
        }
    }

    showAllGridItems() {
        this.gridItems.forEach(item => {
            if (item.classList.contains("hide")) {
                item.classList.remove("hide");
            }
        });
    }

    hideExtraGridItems() {
        this.gridItems.forEach(item => {
            if (item.getAttribute('data-hide-grid-item') == "True")
                item.classList.add("hide");
        });
    }
}