export class ShowMore {
    public toggleButtons: HTMLButtonElement[];
    public itemToToggleClassOn: HTMLElement;
    public showAllClass: string = "show-all";
    public viewAllText: string;
    constructor(toggleButtons: HTMLButtonElement[]) {
        this.toggleButtons = toggleButtons;
        this.activateButtons();
    }
    activateButtons() {
        this.toggleButtons.forEach(button => {
            button.addEventListener('click', () => {
                this.itemToToggleClassOn = document.getElementById(button.dataset.showMore);
                this.classLogic(button);
            });
        });
    }
    classLogic(button: HTMLButtonElement) {
        if (this.itemToToggleClassOn) {
            if (!this.itemToToggleClassOn.classList.contains(this.showAllClass)) {
                this.addShowMoreClass();
                this.itemToToggleClassOn.focus();
                this.viewAllText = button.textContent;
                button.textContent = button.dataset.showLessText;
            }
            else {
                this.removeShowMoreClass();
                button.textContent = this.viewAllText;
            }
        }
    }
    addShowMoreClass() {
        this.itemToToggleClassOn.classList.add(this.showAllClass);
    }
    removeShowMoreClass() {
        this.itemToToggleClassOn.classList.remove(this.showAllClass);
    }
}