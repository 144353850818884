import { nodeListToArray } from "../helpers/nodeListToArray";

export class ToolTips {
    tooltips: HTMLElement[];
    constructor() {
        this.tooltips = nodeListToArray(document.querySelectorAll('[data-tooltip-content]'));
        this.tooltips.forEach(tooltip => {
            this.activateToolTip(tooltip);
        });
    }

    resetToolTip(liveRegion: Element) {
        liveRegion.innerHTML = '';
    }

    toggleToolTip(tooltip: HTMLElement, message: string) {
        const liveRegion = tooltip.nextElementSibling;
        if (liveRegion.innerHTML === '')
            this.openToolTip(tooltip, message, liveRegion);
        else
            this.resetToolTip(liveRegion);
    }

    openToolTip(tooltip: HTMLElement, message: string, liveRegion:Element) {
        window.setTimeout(function () {
            liveRegion.innerHTML = `<span class="tooltip-bubble">${message}</span>`;
        }, 100);
    }

    activateToolTip(tooltip: HTMLElement) {
        const message = tooltip.getAttribute('data-tooltip-content');
        const liveRegion = tooltip.nextElementSibling;

        tooltip.addEventListener('click', () => {
            this.toggleToolTip(tooltip, message);
        });

        document.addEventListener('click', (e) => {
            if (tooltip !== e.target) {
                this.resetToolTip(liveRegion);
            }
        });

        tooltip.addEventListener('keydown', (e) => {
            if ((e.keyCode || e.which) === 27) {
                this.toggleToolTip(tooltip, message);
            }
        });

        tooltip.addEventListener('blur', (e) => {
            this.toggleToolTip(tooltip, message);
        });
    }
}