export class PromoApplied {
    promoElement: HTMLElement;
    constructor(promoElement:HTMLElement) {
        this.promoElement = promoElement;
        if (this.promoElement)
            this.scrollToPromo();
    }
    scrollToPromo() {
        document.querySelector('[data-promo-messaging]').appendChild(this.promoElement);
        window.addEventListener('load', (event) => {
            window.location.hash = '#promoApplied';
        });
    }
}