export class SitewideBanner {
    constructor() {
        let hideButtons = document.querySelectorAll("[id*=hide-banner-]");

        hideButtons.forEach(btn => {
            btn.addEventListener('click', function (e) {
                let banner = getMatchingBanner(btn as HTMLElement);
                banner.classList.add('visually-hidden');

                let guidElement = banner.querySelector("[data-guid]") as HTMLElement;
                if (guidElement) {
                    setCookie(guidElement.dataset.guid);
                }
            })
        });
    }
}

function getMatchingBanner(btn: HTMLElement): HTMLElement {
    let buttonIndex = btn.id.charAt(btn.id.length - 1);
    let bannerId = "site-banner-" + buttonIndex;
    return document.querySelector('#' + bannerId);
}

function setCookie(guid: string): void {
    let cName = "tmc_banner";
    let cDate = new Date();
    cDate.setDate(cDate.getDate() + 1);

    let newCookie = "";
    let cookies = document.cookie.split(';');
    let matchingIndex = cookies.findIndex(c => c.includes(cName));
    if (matchingIndex >= 0) {
        newCookie = cookies[matchingIndex];
        if (!newCookie.includes(guid)) {
            newCookie += "," + guid;
        }
    } else {
        newCookie = cName + "=" + guid;
    }

    newCookie += "; " + cDate.toUTCString();
    document.cookie = newCookie;
}