export class MiniCalendarBlock {
    public showMoreLink: HTMLLinkElement[];
    public showLessLink: HTMLLinkElement[];
    constructor(showMoreToggles: HTMLLinkElement[], showLessToggles: HTMLLinkElement[]) {
        this.showMoreLink = showMoreToggles;
        this.showLessLink = showLessToggles;
        this.activateButtons();
    }
    activateButtons() {
        this.showMoreLink.forEach(link => {
            link.addEventListener('click', () => {
                this.classicLogic(link.dataset.showMore);
            });
        });
        this.showLessLink.forEach(link => {
            link.addEventListener('click', () => {
                this.classicLogic(link.dataset.showLess);
            })
        })
    }

    classicLogic(linkId: string) {
        var shortenedSynopsis = document.querySelector(`[data-shortened-synopsis="${linkId}"]`) as HTMLDivElement;
        var fullSynopsis = document.querySelector(`[data-full-synopsis="${linkId}"]`) as HTMLDivElement;
        if (fullSynopsis.classList.contains('hide')) {
            fullSynopsis.classList.remove('hide');
            shortenedSynopsis.classList.add('hide');
        }
        else {
            fullSynopsis.classList.add('hide');
            shortenedSynopsis.classList.remove('hide');
        }
    }
}