export class SiteSearch {
    siteSearchContainer: HTMLElement;
    siteSearchInput: HTMLInputElement;
    mobileSearchInput: HTMLInputElement;
    siteSearchForm: HTMLElement;
    siteSearchButton: HTMLButtonElement;
    desktopSearchButton: HTMLButtonElement;
    mobileSearchButton: HTMLButtonElement;
    searchButtons: Array<HTMLButtonElement>;
    searchInputs: Array<HTMLInputElement>;
    siteSearchOpen: boolean;
    siteSearchUrl: string;
    constructor(siteSearchContainer: HTMLElement) {
        this.siteSearchContainer = siteSearchContainer;
        this.siteSearchInput = this.siteSearchContainer.querySelector('#site-search-box') as HTMLInputElement;
        this.siteSearchForm = this.siteSearchContainer.querySelector('#searchForm') as HTMLElement;
        this.siteSearchButton = this.siteSearchContainer.querySelector('#site-search-button');
        this.desktopSearchButton = this.siteSearchContainer.querySelector('[data-search-button-desktop]');
        this.mobileSearchButton = document.querySelector('[data-search-button-mobile]');
        this.mobileSearchInput = document.querySelector('[data-search-box-mobile]') as HTMLInputElement;
        this.siteSearchUrl = this.siteSearchContainer.dataset.searchurl;
        this.siteSearchOpen = false;
        this.initArrays();
        this.init();
    }
    get siteSearchExpandedStatus() {
        return this.siteSearchButton.getAttribute('aria-expanded') === 'true';
    }

    initArrays() {
        this.searchButtons = [];
        this.searchInputs = [];
        this.searchButtons.push(this.desktopSearchButton);
        this.searchInputs.push(this.siteSearchInput);
        this.searchButtons.push(this.mobileSearchButton);
        this.searchInputs.push(this.mobileSearchInput);
    }

    init() {
        this.siteSearchButton.addEventListener('click', () => {
            this.toggleSearch(!this.siteSearchExpandedStatus);
        });

        this.activateSearchButtons(this.searchButtons, this.searchInputs);
        this.inputHandler();
    }

    activateSearchButtons(buttons: Array<HTMLButtonElement>, inputs: Array<HTMLInputElement>) {
        buttons.forEach((button, index) => {
            if (button) {
                button.addEventListener('click', () => {
                    if (this.siteSearchButton && inputs[index]) {
                        this.performSearch(inputs[index]);
                    }
                });
            }
        });
    }

    toggleSearch(showSearch: boolean) {
        this.siteSearchButton.setAttribute('aria-expanded', (!this.siteSearchExpandedStatus).toString())
        return showSearch ? this.showSearch() : this.hideSearch();
    }

    showSearch() {
        this.siteSearchForm.classList.remove('hide');
        this.siteSearchOpen = true;
        document.body.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.which == 27 && this.siteSearchOpen) { // Escape key
                this.toggleSearch(false);
                this.siteSearchButton.focus();
            }
        });
        setTimeout(() => {
            this.siteSearchInput.focus();
        }, 100);
    }

    hideSearch() {
        this.siteSearchForm.classList.add('hide');
        this.siteSearchOpen = false;
    }

    performSearch(textInput: HTMLInputElement) {
        if (textInput && (textInput.value != '' || null))
            window.location.href = `${this.siteSearchUrl}?q=${textInput.value}`;
    }

    inputHandler() {
        if (this.siteSearchButton && this.siteSearchInput) {
            this.siteSearchInput.addEventListener('keyup', (e) => {
                e.preventDefault();
                if (e.keyCode == 13) {
                    this.performSearch(this.siteSearchInput);
                }
            });
        }
    }
}
