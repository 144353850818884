import { RevealPanel } from '../../ace2/modules/revealpanel';
const debounce = require('lodash.debounce');

export interface ITabOptions {
    container?: HTMLElement;
}

export class Tabs {
    options: ITabOptions;
    container: HTMLElement;
    panels: Array<HTMLElement>;
    tabs: Array<HTMLButtonElement>;
    mobileTabs: Array<HTMLButtonElement>;
    delay: number;
    keys: any;
    direction: any;
    tabSwitchHandlers: Array<any>;
    breakpoint: number;
    isMobileSize: boolean;
    mobileTabContainer: RevealPanel;
    mobileTabTrigger: HTMLButtonElement;

    constructor(options: ITabOptions) {
        // Most of this is from the WAI-ARIA Authoring Guide and revised for TS https://www.w3.org/TR/wai-aria-practices-1.1/examples/tabs/tabs-1/tabs.html
        this.options = options;
        this.container = options.container;
        this.delay = this.determineDelay();
        this.tabs = [].slice.call(this.container.querySelectorAll('[role="tab"][id*="desktop"]'));
        this.mobileTabs = [].slice.call(this.container.querySelectorAll('[role="tab"][id*="mobile"]'));
        this.panels = [].slice.call(this.container.querySelectorAll('[role="tabpanel"]'));
        const breakpointSetting = this.container.getAttribute('data-tabs-breakpoint');
        this.breakpoint = parseInt(breakpointSetting);
        this.tabSwitchHandlers = [];
        const mobileTabContainerEl = this.container.querySelector('[data-tab-mobile-panel]') as HTMLElement;
        if (mobileTabContainerEl != null) {
            this.mobileTabContainer = new RevealPanel(mobileTabContainerEl.id);
            this.mobileTabTrigger = this.container.querySelector(`[aria-controls="${mobileTabContainerEl.id}"]`);
        }

        // For easy reference
        this.keys = {
            end: 35,
            home: 36,
            left: 37,
            right: 39
        };

        // Add or substract depending on key pressed
        this.direction = {
            37: -1,
            39: 1
        }

        this.checkBrowserSize();

        // Bind listeners
        this.tabs.forEach(tab => {
            tab.addEventListener('click', this.clickEventListener.bind(this));
            tab.addEventListener('keydown', this.keydownEventListener.bind(this));
            tab.addEventListener('keyup', this.keyupEventListener.bind(this));
        });

        // Bind listeners for mobile
        this.mobileTabs.forEach(tab => {
            tab.addEventListener('click', this.mobileClickEventListener.bind(this));
            tab.addEventListener('keydown', this.keydownEventListener.bind(this));
            tab.addEventListener('keyup', this.keyupEventListener.bind(this));
        });

        window.addEventListener('resize', debounce(() => {
            this.checkBrowserSize();
        }, 50));

        // Deactivate all other tabs
        if (this.tabs.length > 0) {
            this.deactivateTabs();
            this.activateTab(this.tabs[0], false, true);
        }

    }

    on(event, handler) {
        switch (event) {
            case "tabSwitch":
                return this.tabSwitchHandlers.push(handler);
        }
    }

    checkBrowserSize() {
        if (window.innerWidth > this.breakpoint) {
            // larger
            this.isMobileSize = false;
        }
        else {
            // smaller
            this.isMobileSize = true;
        }
    }

    // When a tab is clicked, activateTab is fired to activate it
    clickEventListener(event) {
        let tab = event.target;

        // Deactivate all other tabs
        this.deactivateTabs();
        this.activateTab(tab, false);
    }

    mobileClickEventListener(event) {
        this.clickEventListener(event);
        this.mobileTabContainer.closePanel();
    }

    // Handle keydown on tabs
    keydownEventListener(event) {
        const key = event.keyCode;

        switch (key) {
            case this.keys.end:
                event.preventDefault();
                // Deactivate all other tabs
                this.deactivateTabs();
                // Activate last tab
                this.activateTab(this.tabs[this.tabs.length - 1], !this.isMobileSize);
                this.activateTab(this.mobileTabs[this.mobileTabs.length - 1], this.isMobileSize);
                break;
            case this.keys.home:
                event.preventDefault();
                // Deactivate all other tabs
                this.deactivateTabs();
                // Activate first tab
                this.activateTab(this.tabs[0], !this.isMobileSize);
                this.activateTab(this.mobileTabs[0], this.isMobileSize);
                break;
        }
    }

    // Handle keyup on tabs
    keyupEventListener(event) {
        const key = event.keyCode;

        switch (key) {
            case this.keys.left:
            case this.keys.right:
                this.switchTabOnArrowPress(event);
                break;
        }
    }

    // Either focus the next, previous, first, or last tab
    // depening on key pressed
    switchTabOnArrowPress(event) {
        const pressed = event.keyCode;

        this.tabs.forEach(tab => {
            tab.addEventListener('focus', this.focusEventHandler.bind(this));
        });

        this.mobileTabs.forEach(tab => {
            tab.addEventListener('focus', this.focusEventHandler.bind(this));
        });

        if (this.direction[pressed]) {
            const target = event.target;
            const index = parseInt(target.getAttribute('aria-posinset')) - 1;
            if (index !== undefined) {
                if (this.tabs[index + this.direction[pressed]]) {
                    if (this.isMobileSize) {
                        this.mobileTabs[index + this.direction[pressed]].focus();
                    }
                    else {
                        this.tabs[index + this.direction[pressed]].focus();
                    }
                }
                else if (pressed === this.keys.left || pressed === this.keys.up) {
                    this.focusLastTab();
                }
                else if (pressed === this.keys.right || pressed == this.keys.down) {
                    this.focusFirstTab();
                }
            }
        }
    }

    // Activates any given tab panel
    activateTab(tab, setFocus, noCallbacks?: boolean) {
        this.setSelectedTabAttributes(tab);
        const indexOfTab = this.getActiveTabIndex();

        // Make sure both desktop & mobile tab triggers are set as active, semantically
        [this.tabs[indexOfTab], this.mobileTabs[indexOfTab]].forEach(tabToActivate => {
            if (tab !== tabToActivate) {
                this.setSelectedTabAttributes(tabToActivate);
            }
        })

        // Get the value of aria-controls (which is an ID)
        const controls = tab.getAttribute('aria-controls');

        // Remove hidden attribute from tab panel to make it visible
        document.getElementById(controls).removeAttribute('hidden');

        this.mobileTabTrigger.innerHTML = tab.innerHTML;

        // Set focus when required
        if (setFocus) {
            tab.focus();
        }

        if (noCallbacks === null || !noCallbacks) {
            if (this.tabSwitchHandlers) {
                this.tabSwitchHandlers.forEach(handler => {
                    setTimeout(handler.bind(this), 0);
                })
            }
        }
    }

    setSelectedTabAttributes(tab) {
        // Remove tabindex attribute
        tab.removeAttribute('tabindex');

        // Set the tab as selected
        tab.setAttribute('aria-selected', 'true');
    }

    // Deactivate all tabs and tab panels
    deactivateTabs() {
        this.tabs.forEach(tab => {
            tab.setAttribute('tabindex', '-1');
            tab.setAttribute('aria-selected', 'false');
            tab.removeEventListener('focus', this.focusEventHandler.bind(this));
        });
        this.mobileTabs.forEach(tab => {
            tab.setAttribute('tabindex', '-1');
            tab.setAttribute('aria-selected', 'false');
            tab.removeEventListener('focus', this.focusEventHandler.bind(this));
        });
        this.panels.forEach(panel => {
            panel.setAttribute('hidden', 'hidden');
        });
    }

    // Make a guess
    focusFirstTab() {
        if (this.isMobileSize) {
            this.mobileTabs[0].focus();
        }
        else {
            this.tabs[0].focus();
        }
    }

    // Make a guess
    focusLastTab() {
        if (this.isMobileSize) {
            this.mobileTabs[this.mobileTabs.length - 1].focus();
        }
        else {
            this.tabs[this.tabs.length - 1].focus();
        }
    }

    // Determine whether there should be a delay
    // when user navigates with the arrow keys
    determineDelay() {
        const hasDelay = this.container.hasAttribute('data-delay');
        let delay = 0;

        if (hasDelay) {
            const delayValue = parseInt(this.container.getAttribute('data-delay'));
            if (delayValue) {
                delay = delayValue;
            }
            else {
                // If no value is specified, default to 300ms
                delay = 300;
            }
        }

        return delay;
    }

    //
    focusEventHandler(event) {
        const target = event.target;

        setTimeout(this.checkTabFocus.bind(this), this.delay, target);
    }

    // Only activate tab on focus if it still has focus after the delay
    checkTabFocus(target) {
        const focused = document.activeElement;

        if (target === focused) {
            // Deactivate all other tabs
            this.deactivateTabs();
            this.activateTab(target, false);
        }
    }

    getActiveTabPanel() {
        let selected = this.panels[0];
        this.tabs.forEach((tab, index) => {
            if (tab.getAttribute('aria-selected') == 'true' || this.mobileTabs[index].getAttribute('aria-selected') == 'true') {
                selected = this.panels[index];
            }
        });
        return selected;
    }

    getActiveTabIndex() {
        let selected = 0;
        this.tabs.forEach((tab, index) => {
            if (tab.getAttribute('aria-selected') == 'true' || this.mobileTabs[index].getAttribute('aria-selected') == 'true') {
                selected = index;
            }
        });
        return selected;
    }

}