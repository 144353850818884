import { nodeListToArray } from "../helpers/nodeListToArray";

export const pressGalleryPage = () => {
    let hiddenClass = "hide";
    let slideShows: Array<Element> = nodeListToArray(document.querySelectorAll(".ace-gallery-modal-slideshow"));
    slideShows.forEach(slideShow => {
        let slides = nodeListToArray(slideShow.querySelectorAll(".ace-gallery-modal-slide")) as Array<Element>;
        for (let i = 0; i < slides.length; i++) {
            let currentSlide = slides[i];
            let previousButton = currentSlide.querySelector('[data-previous]') as HTMLInputElement;
            let nextButton = currentSlide.querySelector('[data-next]') as HTMLInputElement;

            if (i == 0) {
                previousButton.disabled = true;
            }
            else {
                previousButton.addEventListener('click', () => {
                    let previousSlide = slides[i - 1];
                    currentSlide.classList.add(hiddenClass);
                    previousSlide.classList.remove(hiddenClass)
                })
            }
                
            if (i == slides.length - 1) {
                nextButton.disabled = true;
            }
            else {
                nextButton.addEventListener('click', () => {
                    let nextSlide = slides[i + 1];
                    currentSlide.classList.add(hiddenClass);
                    nextSlide.classList.remove(hiddenClass)
                })
            }
        }
    });
};
