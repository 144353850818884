import { Modal } from '../../Ace2/modules/a11y-modal';
import { nodeListToArray } from '../helpers/nodeListToArray';
import { Stepper } from '../modules/stepper';

interface IStepper {
    id: string;
    stepper: Stepper;
}

export interface IPriceTypeQuantityDependency {
    dependencyPriceType: number;
    dependentPriceType: number;
    unlockedMaximumQuantity: number;
}

export class BestAvailablePage {
    zonesSelector: HTMLSelectElement;
    performanceSelector: HTMLSelectElement;
    selectedZone: string;
    steppers: IStepper[];
    activeZone: HTMLElement[];
    minicart: Array<any>;
    minicartElement: HTMLElement | null;
    minicartList: HTMLElement | null;
    minicartSubtotal: number;
    bestAvailableModal: any;
    zonesArePresent: boolean;
    wheelchairActivityTypes: HTMLInputElement[];
    wheelchairPriceTypes: HTMLInputElement[];
    priceTypeQuantityDependency: IPriceTypeQuantityDependency[];
    constructor() {
        this.zonesSelector = (document.querySelector('[data-zones]') as HTMLSelectElement);
        this.performanceSelector = (document.querySelector('[data-performance]') as HTMLSelectElement);
        this.selectedZone = (this.zonesSelector) ? this.zonesSelector.value : '';
        this.zonesArePresent = (this.zonesSelector) ? true : false;
        this.activeZone = [];
        this.steppers = [];
        this.priceTypeQuantityDependency = [];
        this.minicart = [];
        this.minicartElement = document.querySelector("[data-bestavailable-minicart]");
        this.minicartList = null;
        this.minicartSubtotal = 0;
        this.wheelchairActivityTypes = [];
        this.wheelchairPriceTypes = [];
        this.init();
    }
    currencyFormatter(amount: number) {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        return formatter.format(amount);
    }

    hideAllZones() {
        nodeListToArray(document.querySelectorAll('[data-zone-id]')).forEach(zone => {
            const steppersToReset = this.steppers.filter(stepper => {
                return stepper.id === zone.dataset.zoneId;
            });
            steppersToReset.forEach(stepper => {
                stepper.stepper.reset();
            });
            zone.classList.add('hide');
        });
    }

    showAllZones() {
        nodeListToArray(document.querySelectorAll('[data-zone-id]')).forEach(zone => {
            zone.classList.remove('hide');
        });
    }

    showActiveZone() {
        nodeListToArray(document.querySelectorAll(`[data-zone-id="${this.selectedZone}"]`)).forEach(zone => {
            zone.classList.remove('hide');
            zone.querySelector('[data-total-amount]').innerHTML = `${this.currencyFormatter(0)}`;
        });
    }

    updateZoneChoice(e: Event) {
        this.selectedZone = (e.target as HTMLSelectElement).value;
        this.activeZone = nodeListToArray(document.querySelectorAll(`[data-zone-id="${this.selectedZone}"]`));
        this.hideAllZones();
        this.showActiveZone();
        this.resetMiniCart();
    }

    resetMiniCart() {
        this.minicart = [];
        this.minicartElement.innerHTML = ``;
        this.minicartSubtotal = 0;
        this.buildMiniCartSubtotal();
    }

    calculateZoneAmount(stepper:HTMLElement) {
        const stepperInput = (stepper.querySelector('[data-bestavailable-input]') as HTMLElement);
        const price = parseInt(stepperInput.dataset.price);
        const amountOfTickets = (stepperInput as HTMLInputElement).valueAsNumber;
        const total = price * amountOfTickets;
        this.updateWheelchairActivityType();
        return total;
    }

    calculateZoneFee(stepper: HTMLElement) {
        const stepperInput = (stepper.querySelector('[data-bestavailable-input]') as HTMLElement);
        const fee = parseInt(stepperInput.dataset.fee);
        const amountOfTickets = (stepperInput as HTMLInputElement).valueAsNumber;
        const total = fee * amountOfTickets;
        return total;
    }

    updateWheelchairActivityType() {
        var wheelchairPriceTypeQuantity = 0;
        this.wheelchairPriceTypes = nodeListToArray(document.querySelectorAll('[data-wheelchair-seat="True"]'));
        this.wheelchairPriceTypes.forEach(quantity => {
            if (quantity.valueAsNumber > 0)
                wheelchairPriceTypeQuantity++;
        });
        var wheelchairActivityTypesList = nodeListToArray(document.querySelectorAll('[data-activity-type-list]'));
        wheelchairActivityTypesList.forEach(activityTypeList => {
            if (wheelchairPriceTypeQuantity > 0)
                activityTypeList.classList.remove('hide');
            else
                activityTypeList.classList.add('hide');
        });
        this.wheelchairActivityTypes = nodeListToArray(document.querySelectorAll('[data-activity-type-radio]'));
        this.wheelchairActivityTypes.forEach(radioButton => {
            if (wheelchairPriceTypeQuantity == 0)
                radioButton.checked = false;
        });
        
    }

    updateTotals(stepper: HTMLElement) {
        this.resetMiniCart();
        const totalAmountText = document.querySelector(`#${stepper.getAttribute('data-bestavailable-item-total')}`);
        const total = this.calculateZoneAmount(stepper) + this.calculateZoneFee(stepper);
        totalAmountText.innerHTML = (!isNaN(total)) ? `${this.currencyFormatter(total)}` : ``;
    }

    unlockPriceTypes(actualStepper: HTMLElement, steppers: HTMLElement[]) {

        //Get price type id from the actual stepper
        const priceTypeId = parseInt(actualStepper.getAttribute("data-price-type-id"));

        //find if the actual stepper is a dependency
        if (this.priceTypeQuantityDependency.length > 0) {
            const priceTypeDependency = this.priceTypeQuantityDependency.filter(pt => pt.dependencyPriceType == priceTypeId);

            //find if the actual stepper is a dependent
            const priceTypeDependent = this.priceTypeQuantityDependency.filter(pt => pt.dependentPriceType == priceTypeId);

            const actualStepperInput = actualStepper.querySelector('[data-bestavailable-input]');
            const amountOfTickets = (actualStepperInput as HTMLInputElement).valueAsNumber;

            if (priceTypeDependency) {
                priceTypeDependency.forEach(pt => {
                    var childDependencies = nodeListToArray(document.querySelectorAll(`[data-price-type-id="${pt.dependentPriceType}"]`));
                    if (childDependencies != undefined) {
                        childDependencies.forEach(child => {
                            var actualInput = child.querySelector("[data-bestavailable-input]");
                            const upButton = child.querySelector('[data-bestavailable-up]');
                            actualInput.value = amountOfTickets <= 0 ? "0" : actualInput.value;
                            child.style.display = amountOfTickets > 0 ? "" : "none";
                            var QuantityMaxOfTickets = amountOfTickets * pt.unlockedMaximumQuantity;
                            if (actualInput.value > QuantityMaxOfTickets) {
                                actualInput.value = QuantityMaxOfTickets;
                            }
                            if (actualInput.value != QuantityMaxOfTickets) {
                                upButton.removeAttribute('disabled', 'disabled');
                            }
                            else {
                                upButton.setAttribute('disabled', 'disabled');
                            }

                        })
                    }
                })
            }
            if (priceTypeDependent) {
                priceTypeDependent.forEach(pt => {
                    var childDependent = nodeListToArray(document.querySelectorAll(`[data-price-type-id="${pt.dependencyPriceType}"]`));
                    if (childDependent != undefined) {
                        childDependent.forEach(child => {
                            var actualInput = child.querySelector("[data-bestavailable-input]");
                            const upButton = actualStepper.querySelector('[data-bestavailable-up]');
                            var QuantityMaxOfTickets = actualInput.value * pt.unlockedMaximumQuantity;
                            if ((amountOfTickets + 1) > QuantityMaxOfTickets) {
                                upButton.setAttribute('disabled', 'disabled');
                            }
                        })
                    }
                })

            }
        }
    }

    updatePerformanceChoice = (e) => {
        var currentLink = window.location.href;
        var url = new URL(currentLink);
        var performanceId = url.searchParams.get("performanceId");
        var targetPerformance = (e.target as HTMLSelectElement).value;
        if (performanceId == undefined) {
            currentLink = currentLink.substring(0, currentLink.length - 1);
            performanceId = (document.querySelector('#PerformanceId') as HTMLInputElement).value;
            var productionId = (document.querySelector('#ProductionSeasonId') as HTMLInputElement).value;
            window.location.href = currentLink + "?productionSeasonId=" + productionId + "&performanceId=" + performanceId + "&scroll";
        }
        else {
            window.location.href = url.searchParams.has("scroll") ? currentLink.replace(performanceId, targetPerformance) : currentLink.replace(performanceId, targetPerformance) + "&scroll";
        }
    }

    scrollUp() {
        var startElement = document.getElementById('PerformanceDropdown');
        var navHeight = document.getElementById('nav-container').getBoundingClientRect().height;
        startElement.scrollIntoView();

        var scrolledY = window.scrollY;
        if (scrolledY) {
            window.scroll(0, scrolledY - navHeight);
        }
    }

    buildMiniCartMarkup() {
        const miniCartList = document.createElement('ul');
        miniCartList.classList.add('ace-minicart-list');
        this.minicartElement.appendChild(miniCartList);
        this.minicartList = miniCartList;
    }

    buildMiniCartSubtotal() {
        document.querySelector('[data-bestavailable-minicart-subtotal]').innerHTML = `${this.currencyFormatter(this.minicartSubtotal)}`;
    }

    toggleCheckOutButtonDisability(disabled: boolean) {
        (document.querySelector('[data-button-handle-checkout]') as HTMLButtonElement).disabled = disabled;
    }

    buildMiniCart() {
        nodeListToArray(document.querySelectorAll('[data-zone-id]:not(.hide)')).forEach(zone => {
            const zoneInputAmount = (zone.querySelector('[data-bestavailable-input]') as HTMLInputElement).valueAsNumber;
            const zoneStepper = (zone.querySelector('[data-stepper]') as HTMLElement);
            const zoneName = zoneStepper.getAttribute('data-zone-name');
            if (zoneInputAmount > 0) {
                const cartItem = {
                    "zoneAmount": zoneInputAmount,
                    "zoneName": zoneName,
                    "zoneCost": this.calculateZoneAmount(zoneStepper),
                    "zoneFee": this.calculateZoneFee(zoneStepper)
                }
                this.minicartSubtotal += this.calculateZoneAmount(zoneStepper) + this.calculateZoneFee(zoneStepper);
                this.minicart.push(cartItem);
            }
        });
        if (this.minicart.length > 0) {
            this.buildMiniCartMarkup();
            this.minicart.forEach(item => {
                const itemElement = document.createElement('li');
                itemElement.innerHTML = `<div class="ace-minicart-item-row"><span>(${item.zoneAmount}) ${item.zoneName}</span><span class="bold">${this.currencyFormatter(item.zoneCost)}</span></div>`
                itemElement.innerHTML += `<div class="ace-minicart-item-row"><span>(${item.zoneAmount}) Fee </span><span class="bold">${this.currencyFormatter(item.zoneFee)}</span></div>`;
                itemElement.classList.add('ace-minicart-item');
                itemElement.classList.add('ace-minicart-item-multiline');
                this.minicartList.appendChild(itemElement);
            });
            this.toggleCheckOutButtonDisability(false);
        }
        else
            this.toggleCheckOutButtonDisability(true);
        this.buildMiniCartSubtotal();
    }

    cancelWheelchairDialog() {
        (document.querySelector('[data-wheelchair-seating-checkbox]') as HTMLInputElement).checked = false;
    }

    init() {
        const steppers: HTMLElement[] = nodeListToArray(document.querySelectorAll('[data-stepper]'));

        //Get the windows price type dependency
        const priceTypeDependencies = (window as any).priceTypeQuantityDependencies;
        //We parse the incoming object to an interface
        if (priceTypeDependencies && priceTypeDependencies.length > 0) {
            this.priceTypeQuantityDependency = priceTypeDependencies.map((p: any) => ({
                dependencyPriceType: p.dependencyPriceType,
                dependentPriceType: p.dependentPriceType,
                unlockedMaximumQuantity: p.unlockedMaximumQuantity
            }));
        }

        steppers.forEach(stepper => {
            const stepperObject = new Stepper(stepper);
            this.steppers.push({
                id: stepper.dataset.stepper,
                stepper: stepperObject
            });

            const upButton = stepper.querySelector('[data-bestavailable-up]');
            const downButton = stepper.querySelector('[data-bestavailable-down]');
            const stepperInput = stepper.querySelector('[data-bestavailable-input]');

            //Get price type id from the actual stepper
            const priceTypeId = parseInt(stepper.getAttribute("data-price-type-id"));

            //find if the actual stepper is a dependent
            if (this.priceTypeQuantityDependency.length > 0) {
                const priceTypeDependent = this.priceTypeQuantityDependency.filter(pt => pt.dependentPriceType == priceTypeId);
                priceTypeDependent.forEach(pt => {
                    var childDependencies = nodeListToArray(document.querySelectorAll(`[data-price-type-id="${pt.dependentPriceType}"]`));
                    if (childDependencies != undefined) {
                        childDependencies.forEach(child => {
                            child.style.display = "none";
                        })
                    }
                })
            }

            if (stepper.dataset.available === "True") {
                upButton.addEventListener('click', () => {
                    this.updateTotals(stepper);
                    this.unlockPriceTypes(stepper, steppers);
                    this.buildMiniCart();
                });
                downButton.addEventListener('click', () => {
                    this.updateTotals(stepper);
                    this.unlockPriceTypes(stepper, steppers);
                    this.buildMiniCart();
                });
                stepperInput.addEventListener('keyup', () => {
                    this.updateTotals(stepper);
                    this.unlockPriceTypes(stepper, steppers);
                    this.buildMiniCart();
                });
            }
            else {
                upButton.setAttribute('disabled', 'disabled');
                downButton.setAttribute('disabled', 'disabled');
                stepperInput.setAttribute('disabled', 'disabled');
            }
        });
        if (this.zonesArePresent) {
            this.showActiveZone();
        }
        else {
            this.showAllZones();
        }
        //Activate Listeners
        if (this.zonesSelector) {
            this.zonesSelector.addEventListener('change', (e) => {
                this.updateZoneChoice(e);
            });
        }
        if (this.performanceSelector) {
            this.performanceSelector.addEventListener('change', (e) => {
                this.updatePerformanceChoice(e);
            });
            var currentLink = window.location.href;
            var url = new URL(currentLink);
            if (url.searchParams.has("scroll")) {
                this.scrollUp();
            }
        }
        if (document.querySelector('[data-bestavailable-modal]')) {
            //Modal Init 
            this.bestAvailableModal = new Modal(document.querySelector('[data-bestavailable-modal]'));
            this.bestAvailableModal.modal.on('hide', (element, event) => {
                this.cancelWheelchairDialog();
            });
        }
    }
}