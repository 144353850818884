export class Stepper {
    stepperElement: HTMLElement;
    upButton: HTMLButtonElement;
    downButton: HTMLButtonElement;
    numberElement: HTMLInputElement;
    upLabel: string;
    downLabel: string;
    min: number;
    max: number;
    stepAmount?: number;
    defaultStart = 0;

    constructor(stepperElement: HTMLElement, stepAmount?: number) {
        this.stepperElement = stepperElement;
        this.initElements();
        this.initListeners();
        this.initMinMaxAmount();
        this.stepAmount = stepAmount ? stepAmount : 1;
        this.numberElement.valueAsNumber = this.defaultStart;
        this.updateButtonsEnabled();
        this.updateLabels();
    }

    initElements = () => {
        this.upButton = this.stepperElement.querySelector('button.stepper-btn-up');
        this.downButton = this.stepperElement.querySelector('button.stepper-btn-down');
        this.numberElement = this.stepperElement.querySelector('[data-stepper-target]');
        this.upLabel = this._upLabel;
        this.downLabel = this._downLabel;
    }

    initListeners = () => {
        this.upButton.addEventListener("click", () => { this.addSteps() });
        this.downButton.addEventListener("click", () => { this.decreaseSteps() });
        this.numberElement.addEventListener("keyup", () => { this.checkTypedValue() });
    }

    updateAll = () => {
        this.updateButtonsEnabled();
        this.updateLabels();
    }

    checkTypedValue = () => {
        if (!isNaN(this.numberElement.valueAsNumber)) {
            const valueTooHigh = this.numberElement.valueAsNumber > this.max;
            this.numberElement.valueAsNumber = valueTooHigh ? this.max : this._numberValue;
            this.updateAll();
        }
    }

    initMinMaxAmount = () => {
        let minAttribute = this.stepperElement.getAttribute('data-stepper-min')
        let maxAttribute = this.stepperElement.getAttribute('data-stepper-max')
        let amountAttribute = this.stepperElement.getAttribute('data-stepper-step-amount');

        this.min = minAttribute ? parseInt(minAttribute) : Number.MIN_VALUE;
        this.max = maxAttribute ? parseInt(maxAttribute) : Number.MAX_VALUE;
        this.stepAmount = amountAttribute ? parseInt(amountAttribute) : 1;
    }

    addSteps = () => {
        let tooHigh = this._numberValue + this.stepAmount > this.max;
        this.numberElement.valueAsNumber = tooHigh ? this.max : this._numberValue + this.stepAmount;
        this.updateAll();
    }

    decreaseSteps = () => {
        let tooLow = this._numberValue - this.stepAmount < this.min;
        this.numberElement.valueAsNumber = tooLow ? this.min : this._numberValue - this.stepAmount;
        this.updateAll();
    }

    reset = () => {
        this.numberElement.valueAsNumber = this.min;
        this.updateAll();
    }

    updateButtonsEnabled = () => {
        let tooLow = this._numberValue === this.min;
        let tooHigh = this._numberValue === this.max || this.min === this.max;

        tooLow ? this.downButton.disabled = true : this.downButton.disabled = false;
        tooHigh ? this.upButton.disabled = true : this.upButton.disabled = false;
    }

    updateLabels = () => {
        let upLabel = this._upLabel;
        let downLabel = this._downLabel;

        upLabel = upLabel.replace('<<steps>>', this.stepAmount.toString());
        upLabel = upLabel.replace('<<sum>>', this._numberValue.toString());

        downLabel = downLabel.replace('<<steps>>', this.stepAmount.toString());
        downLabel = downLabel.replace('<<sum>>', this._numberValue.toString());

        this.upButton.setAttribute('aria-label', upLabel);
        this.downButton.setAttribute('aria-label', downLabel);
    }

    get _numberValue() {
        return this.numberElement.valueAsNumber;
    }

    get _upLabel() {
        return this.stepperElement.getAttribute('data-stepper-label-up');
    }

    get _downLabel() {
        return this.stepperElement.getAttribute('data-stepper-label-down');
    }

    get _valueTooHigh() {
        return
    }
}