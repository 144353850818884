import { Accordion } from './modules/accordion';
import { SiteSearch } from './modules/site-search';
import { SitewideBanner } from './modules/site-banner';
import { SvgSprite } from './modules/svg-loader';
import { nodeListToArray } from './helpers/nodeListToArray';
import { ToolTips } from './modules/tooltips';
import { GridBlock } from './modules/grid-block';
import { Gallery } from './modules/gallery';
import { Playlist } from './modules/playlist';
import { mainNavigation } from './modules/mainNavigation';
import { vhUnitUpdate, vhUnitInitial } from './helpers/vhUnitFix';
import throttle = require('lodash.throttle');
import debounce = require('lodash.debounce');
import { Tabs } from './modules/tabs';
import { pressGalleryPage } from './pages/pressGalleryPage';
import { MiniCalendarBlock } from './modules/mini-calendar';
import { ShowMore } from './modules/showMore';
import { PromoApplied } from './modules/promoApplied';
import { BestAvailablePage } from './pages/best-available';

// These are needed for the vh fix (to make mobile browsers happy)
let previousPageWidth = 0;
let previousPageHeight = 0;

new SitewideBanner();
//new SiteNavigation();

const init = () => { 
    new SvgSprite('/Static/img/adage-sprite.svg');
    blocksInit();
    tooltipsInit();
    gridBlockInit();
    miniCalendarInit();
    navigationInit();
    siteSearchInit();
    tabsInit();
    pressGalleryPage();
    promoAppliedInit();
    bestAvailableInit();
}

const tooltipsInit = () => {
    new ToolTips();
}

const gridBlockInit = () => {
    if (nodeListToArray(document.querySelectorAll('[data-showmore-grid]')).length > 0) {
        nodeListToArray(document.querySelectorAll('[data-showmore-grid]')).forEach(item => {
            const showAllShowLessTriggers = (nodeListToArray(item.querySelectorAll('[data-show-more]')) as HTMLButtonElement[]);
            const typeOfBlock = item.getAttribute('data-showmore-grid');
            if (typeOfBlock !== "grid")
                new ShowMore(showAllShowLessTriggers);
            else
                new GridBlock(showAllShowLessTriggers);
        });
    }
}

const miniCalendarInit = () => {
    if (nodeListToArray(document.querySelectorAll('[data-mini-calendar]')).length > 0) {
        nodeListToArray(document.querySelectorAll('[data-mini-calendar]')).forEach(miniCalendar => {
            const showMoreTriggers = (nodeListToArray(miniCalendar.querySelectorAll('[data-show-more]')) as HTMLLinkElement[]);
            const showLessTriggers = (nodeListToArray(miniCalendar.querySelectorAll('[data-show-less]')) as HTMLLinkElement[]);
            new MiniCalendarBlock(showMoreTriggers, showLessTriggers);
        });
    }
}

const blocksInit = () => {
    // Accordion Init
    const accordions = nodeListToArray(document.querySelectorAll('[data-accordion-list]'));
    accordions.forEach(accordion => {
        const oneOpen = accordion.getAttribute('data-one-open-at-a-time');
        new Accordion({
            container: accordion,
            oneOpenAtATime: oneOpen === 'True'
        });
    });

    // Gallery Init
    let galleryClass = 'single-item-gallery';
    let galleries = nodeListToArray(document.querySelectorAll('[data-gallery]'));
    galleries.forEach((gallery, index) => {
        gallery.classList.add(`${galleryClass}-${index}`);
        new Gallery(gallery);
    });

    // Playlist Init
    let playlistClass = 'tmc-playlist';
    let playlists = nodeListToArray(document.querySelectorAll('[data-playlist]'));
    playlists.forEach((playlist, index) => {
        playlist.classList.add(`${playlistClass}-${index}`);
        new Playlist(playlist);
    });
}

const resizeVhUpdates = () => {
    const pageWidth = window.innerWidth;
    const pageHeight = window.innerHeight;

    // Smaller resizes might be on scroll, so this measures if it's safe to treat it as a fully new browser size and recalculate.
    if (Math.abs(previousPageWidth - pageWidth) > 60 || Math.abs(previousPageHeight - pageHeight) > 60) {
        vhUnitInitial();
    }

    // This will get called on scroll-triggered resizes, so can be used for full-height elements that have their own scrollbar.
    // if the body scrollbar is locked, then it requires someone force scrolling at the bottom to change the height at that point
    vhUnitUpdate();
    previousPageWidth = pageWidth;
    previousPageHeight = pageHeight;
}

const navigationInit = () => {
    if (mainNavigation) mainNavigation.init();
    // Setup for determining vh unit size
    previousPageWidth = window.innerWidth;
    previousPageHeight = window.innerHeight;

    // I moved these out of the nav code just in case we need to add any more stuff (like alerts?) that might interact
    // with the sizing and alignment of the navigation
    document.addEventListener('scroll', throttle(() => {
        if (mainNavigation) mainNavigation.scrollHandler();
    }, 10));
    window.addEventListener('resize', debounce(() => {
        if (mainNavigation) mainNavigation.resizeHandler();
        resizeVhUpdates();
    }, 50));
}

const siteSearchInit = () => {
    const siteSearchContainer = document.querySelector('#site-search-container') as HTMLElement;
    if (siteSearchContainer) {
        new SiteSearch(siteSearchContainer);
    } 
}

const tabsInit = () => {
    const tabsLists = nodeListToArray(document.querySelectorAll('[data-tabs-container]'));
    if (tabsLists.length > 0) {
        tabsLists.forEach(list => {
            new Tabs({
                container: list
            });
        });
    }
}

const promoAppliedInit = () => {
    const promoElement = document.querySelector("[data-promo-applied]") as HTMLElement;
    new PromoApplied(promoElement);
}

const bestAvailableInit = () => {
    new BestAvailablePage();
}

init();